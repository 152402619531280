import styled, { css } from "styled-components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

export const TextFieldStyle = css`
  width: 100%;

  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiFormHelperText-root {
    font-family: "Montserrat", sans-serif !important;
    font-display: swap;
    font-weight: normal;
    color: #263242;
  }

  .MuiOutlinedInput-root {
    border-radius: 12px;
  }

  .MuiSelect-root {
    position: relative;
    z-index: 1000;
  }

  .MuiInputBase-formControl {
    .MuiSvgIcon-root {
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }
  }

  .MuiButtonBase-root {
    right: 10px;
    @media (max-width: 374px) {
      right: -8px;
    }
  }

  .MuiInputBase-input {
    color: #263242;
    /* Hide Arrows From Input Number  */

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const DatePicker = styled(KeyboardDatePicker)`
  ${TextFieldStyle}
`;

export const DatePickerPopover = {
  "& .MuiPickersToolbar-toolbar": {
    backgroundColor: "#526df2",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    height: "70px",
  },
  "& .MuiTypography-h4": {
    fontSize: "16px",
  },
  "& .MuiPopover-paper": {
    borderRadius: "20px",
    fontFamily: "Montserrat !important",
    fontDisplay: "swap",
  },
  "& .MuiPickersDay-daySelected": {
    backgroundColor: "#526df2",
  },
  "& .MuiTypography-body1": {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  "& .MuiTypography-caption": {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  "& .MuiTypography-body2": {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
};

const labelStyle = {
  border: "1px solid #ecf0f1",
  borderRadius: "16px",
  padding: "16px 16px",
  width: "100%",
  margin: "16px 0 0 0",
  marginLeft: "0!important",
  marginRight: "0!important",
  "& .MuiTypography-root": {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "1.36",
    color: "#263242",
  },
  "& .MuiButtonBase-root": {
    marginRight: "9px",
    padding: 0,
  },
};
export const materialUIcomponentsStyles = makeStyles({
  formControlStyles: {
    width: "100%",
  },
  radioButtonStyles: {
    color: "#526df2!important",
  },
  radioButtonContainerStyle: {
    ...labelStyle,
  },
  checkedRadio: {
    ...labelStyle,
    backgroundColor: "#f6f9fa",
    border: "none",
  },
  radioButtonsContainerStyle: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    marginRight: "14px",
    marginLeft: "14px",
  },
  popoverPropsStyle: DatePickerPopover,
});
