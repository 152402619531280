import React, { ReactElement, useState, useRef } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import styles from "./styles.mod.scss";
import Logo from "../../assets/logo-dark.svg";

import "./styles.scss";

interface NavBarProps {
  onlyLogo?: boolean;
}

function NavBar({ onlyLogo = false }: NavBarProps): ReactElement {
  const [navBarState, setNavBarState] = useState<boolean>(false);

  const mobileMenuRef = useRef(null);

  const toggleMobileMenu = () => setNavBarState(!navBarState);

  const onMobileMenuBackgroundClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.target === mobileMenuRef.current) {
      toggleMobileMenu();
    }
  };
  const getNavBarClasses = () => {
    return classnames("navbar", "navbar-expand-lg", styles.navbar__padding);
  };

  const getNavBarLinksClasses = () => {
    return classnames(
      "collapse",
      "d-none d-lg-flex",
      "justify-content-end align-self-end",
      "flex-grow-1"
    );
  };

  const menuMobile = classnames("collapse", "d-lg-none", styles.navbar__menu, {
    [styles.navbar__menu_visible]: navBarState,
  });

  const mobileMenuContainerCn = classnames(styles.mobile_menu__container, {
    [styles.mobile_menu__container_visible]: navBarState,
  });

  const togglerLineCn = classnames(styles.navbar__toggler_line, {
    [styles.navbar__toggler_line__close]: navBarState,
  });

  const togglerClose = classnames("navbar-toggler", {
    [styles.navbar__toggler__close]: navBarState,
    [styles.navbar__toggler__open]: !navBarState,
  });

  const Tag = onlyLogo ? "header" : "nav";

  return (
    <div className={styles.navBarContainer}>
      <div className="container">
        <Tag className={getNavBarClasses()}>
          <Link className="navbar-brand" to="/">
            <img alt="image-logo" src={Logo} />
          </Link>
          {!onlyLogo && (
            <>
              <div className="toggler-wrapper">
                <button
                  onClick={toggleMobileMenu}
                  className={togglerClose}
                  type="button"
                >
                  <span className={togglerLineCn}></span>
                  <span className={togglerLineCn}></span>
                  <span className={togglerLineCn}></span>
                </button>
              </div>

              <div className={getNavBarLinksClasses()}>
                <ul className="navbar-nav mt-2 mt-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/blog/all-articles">
                      stromee Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/jobs/all-jobs">
                      Jobs
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                ref={mobileMenuRef}
                onClick={onMobileMenuBackgroundClick}
                className={menuMobile}
              >
                <div className={mobileMenuContainerCn}>
                  <ul className="navbar-nav mobile-nav">
                    <li className="nav-item">
                      <Link
                        onClick={toggleMobileMenu}
                        className="nav-link"
                        to="/faq"
                      >
                        FAQ
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={toggleMobileMenu}
                        className="nav-link"
                        to="/blog/all-articles"
                      >
                        stromee Blog
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={toggleMobileMenu}
                        className="nav-link"
                        to="/jobs/all-jobs"
                      >
                        Jobs
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </Tag>
      </div>
    </div>
  );
}

export default NavBar;
