import { confirmRestorePasswordApi } from "../settings";
import { ConfirmRestorePasswordRequest } from "../types";
import request from "./request";

export const restorePasswordRequest = (
  restorePassword: ConfirmRestorePasswordRequest
): Promise<{
  customer: {
    firstname?: string;
    surname: string;
    username: string;
  };
}> => {
  return request(confirmRestorePasswordApi, {
    method: "post",
    body: JSON.stringify(restorePassword),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
