import styled from "styled-components";
import { Button } from "../Button";

export const FormContainer = styled.div``;

export const Form = styled.form``;

export const FieldsContainer = styled.div`
  margin-bottom: 32px;
  padding: 32px 36px 24px;
  border-radius: 18px;
  box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  background-color: #fff;

  @media (max-width: 576px) {
    padding: 32px 24px 24px;
  }
`;

export const SubmitButton = styled(Button)`
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
