import styled from "styled-components";
import FormControlOriginal, {
  FormControlProps,
} from "@material-ui/core/FormControl";

import { TextFieldStyle } from "../../styled";

interface CustomFormControlProps extends FormControlProps {
  firstElement?: boolean;
}

export const FormControl = styled(FormControlOriginal)<CustomFormControlProps>`
  ${TextFieldStyle}

  ${({ error, firstElement }) =>
    !error && !firstElement
      ? `
      margin-bottom: 19px !important;
  `
      : !error && firstElement
      ? `
      margin-bottom: 29px !important;
  `
      : `
      margin-bottom: 10px !important;
  `}

  .MuiInputBase-formControl .MuiSvgIcon-root {
    color: #abb3b5;
  }
`;
