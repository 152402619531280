import React, { ReactElement, useState } from "react";
import { Message } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput, {
  OutlinedInputProps,
} from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { FormControl } from "./styled";

interface PasswordFieldProps extends OutlinedInputProps {
  id: string;
  label: string;
  labelWidth: number;
  helperText?: Message;
  firstElement?: boolean;
}

function PasswordField({
  id,
  label,
  labelWidth,
  error,
  helperText,
  firstElement,
  ...InputProps
}: PasswordFieldProps): ReactElement {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  const describedbyId = `${id}-describedby`;

  return (
    <FormControl variant="outlined" error={error} firstElement={firstElement}>
      <InputLabel htmlFor={id} error={error}>
        {label}
      </InputLabel>
      <OutlinedInput
        {...InputProps}
        id={id}
        aria-describedby={describedbyId}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth}
        error={error}
      />
      <FormHelperText id={describedbyId} error={error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default PasswordField;
