import { ValidationOptions } from "react-hook-form";

import { PASSWORD_VALIDATION_RULES } from "../Contract/validation";

const PASSWORD_PATTERN_ERROR_MESSAGE =
  "Passwortanforderungen: Mindestlänge 8 Zeichen; mind. ein Groß- und ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen (!#$%&'*+-/=?^_`{|}~.,;:()@)";

const PASSWORDS_NOT_MATCH = "Passwörter nicht identisch";

export const passwordValidation: ValidationOptions = {
  pattern: {
    value: PASSWORD_VALIDATION_RULES,
    message: PASSWORD_PATTERN_ERROR_MESSAGE,
  },
  validate: (value: string) => {
    if (!value) {
      return PASSWORD_PATTERN_ERROR_MESSAGE;
    }

    return true;
  },
};

export const passwordRepeatValidation = (
  password: string
): ValidationOptions => ({
  pattern: {
    value: PASSWORD_VALIDATION_RULES,
    message: PASSWORD_PATTERN_ERROR_MESSAGE,
  },
  validate: (value: string) => {
    if (!value) {
      return PASSWORD_PATTERN_ERROR_MESSAGE;
    }

    if (password === value) {
      return true;
    }

    return PASSWORDS_NOT_MATCH;
  },
});
