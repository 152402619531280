import React, { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

import { PasswordRecoveryFormValues } from "../../types";
import PasswordField from "../Contract/Form/PasswordField";

import { FieldsContainer, Form, FormContainer, SubmitButton } from "./styled";
import { passwordRepeatValidation, passwordValidation } from "./validation";

interface PasswordRecoveryForm {
  onSubmit: (values: PasswordRecoveryFormValues) => Promise<void>;
}

function PasswordRecoveryForm({
  onSubmit,
}: PasswordRecoveryForm): ReactElement {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
    triggerValidation,
  } = useForm<PasswordRecoveryFormValues>({
    mode: "onBlur",
    defaultValues: {
      password: "",
      passwordRepeat: "",
    },
  });

  const { password, passwordRepeat } = watch(["password", "passwordRepeat"]);

  useEffect(() => {
    if (password && passwordRepeat) {
      triggerValidation("passwordRepeat");
    }
  }, [password, triggerValidation]);

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <PasswordField
            id="password"
            name="password"
            label="Passwort"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            inputRef={register(passwordValidation)}
            labelWidth={75}
            firstElement
          />
          <PasswordField
            id="passwordRepeat"
            name="passwordRepeat"
            label="Passwort wiederholen"
            error={Boolean(errors.passwordRepeat)}
            helperText={errors.passwordRepeat?.message}
            inputRef={register(passwordRepeatValidation(password))}
            labelWidth={180}
          />
        </FieldsContainer>
        <SubmitButton type="submit" disabled={formState.isSubmitting}>
          Bestätigen
        </SubmitButton>
      </Form>
    </FormContainer>
  );
}

export default PasswordRecoveryForm;
